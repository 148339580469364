<template>
  <div>

    <div class="d-flex justify-content-center h-100 content">
      <b-row class="w-100" style="max-width: 1200px;">

        <b-col md="12">
          <h1 class="mt-4" style="font-size: 25px;">{{ 'how to enroll' | capitalizeAll }}</h1>
          <h2 style="font-size: 20px;">Certified solutions curated for Honda dealers</h2>
        </b-col>

        <b-col md="8" class="mt-4">
          
          <p>The Honda Digital Solutions enrollment site provides a convenient and secure place to manage all of your digital enrollments. We have carefully vetted both provider and service options to offer you a streamlined selection of integrated services to cover dealer websites, digital advertising, SEO and more.</p>
          <p>To sign up for a Full-Service Package, you simply select your preferred certified vendor from our five choices. It's that easy. By selecting your preferred vendor for all three services, you will enjoy the benefits of simplicity, single strategy and planning - all within a coordinated digital marketing playbook.</p>
          <p>Enrollment in the program is now open! While this website is useful for learning about the program, actual enrollment takes place in the <a target="_BLANK" href="https://login.smartdealerportal.com/login">Autodata Solutions Customer Portal</a>. If you do not have your login credentials, please contact either your Dealer Principal or District Manager.</p>
          
          <a target="_BLANK" href="/hds_enrollment_guide_w_screenshots.pdf">Click for printable enrollment instructions</a>


          <h3 style="font-size: 20px; font-weight: 600; margin-top: 25px;">Certified Full-Service Packages</h3>
          <ul>
            <li>Dealer.com</li>
            <li>DealerOn</li>
            <li>Dealer eProcess</li>
            <li>Team Velocity</li>
            <li>Dealer Inspire</li>
          </ul>

          <p style="margin-bottom: 0.5rem;">In addition to the three core solutions, we are also offering an integrated package for the following functions:</p>
          <ul>
            <li>Trade-in</li>
            <li>Chat</li>
          </ul>

          <h3 style="font-size: 20px; font-weight: 600; margin-top: 25px;">Mix and Match Providers</h3>
          <p>If a Full-Service Package is not the right fit for your dealership, you can choose to build your own program with our Mix and Match providers. With the Mix and Match method, you gain some of the benefits of the program; however, the Full-Service options save you up to 15% over Mix and Match choices and create a more streamlined digital experience for your dealership.</p>
          
          <h3 style="font-size: 20px; font-weight: 600; margin-top: 25px;">Website Mix and Match Providers</h3>
          <ul>
            <li>Dealer.com</li>
            <li>DealerOn</li>
            <li>Dealer eProcess</li>
            <li>Dealer Inspire</li>
            <li>FusionZONE</li>
            <li>Nabthat</li>
            <li>Team Velocity</li>
          </ul>

          <h3 style="font-size: 20px; font-weight: 600; margin-top: 25px;">Digital Advertising Mix and Match Providers</h3>
          <ul>
            <li>Adpearance</li>
            <li>Dealer.com</li>
            <li>DealerOn</li>
            <li>Dealer eProcess</li>
            <li>DealerX</li>
            <li>Team Velocity</li>
            <li>Dealer Inspire</li>
            <li>C-4 Analytics</li>
            <li>PureCars</li>
            <li>Stream Companies</li>
          </ul>

        </b-col>

        <b-col md="4">
           <b-img
              fluid
              src="@/assets/how_to_enroll.jpg"
              style="margin-top:25px; padding: 10px;"
              class="photo"
            />
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
  export default {

    name: 'how-to-enroll',

    metaInfo: {
      title: 'How to Enroll'
    },

  }

</script>

<style scoped>

  .photo {
    margin-right: 35px;
  }

  .content {
    margin-top: 20px;
  }
  
  @media screen and (max-width: 768px) {

    .content {
      margin-top: 0px;
      padding-top: 60px;
    }

   .photo {
     margin-right: 0px;
   }

  }

</style>